import { useQuery } from "@tanstack/react-query";
import { Empty, Select, Table, Tag } from "antd";
import { DemoPanelDedicatedApplicants } from "components/layouts/dashboard/basic/db-premium";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel, { DemoCandidateBrandExperiencePanel } from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel, { DemoMessagesPanel } from "components/layouts/dashboard/panels/messages-panel";
import DashboardRecruitment from "components/layouts/dashboard/recruitment/db-recruitment";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import DemoBarChart from "components/modules/demo/demo-bar-chart";
import Panel from "components/modules/panel/panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { handleAllPermissions } from "components/utils/methods/MyMethods";
import { sendAxiosGetRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function BusinessSalesDashboardPage(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const [PM_DashboardView, setPM_DashboardView] = useState(false);
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId: null, filterUserId: null, enabled: true });

    const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
        queryKey: ["dbGetAllPhases"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/JobPhases`,
            callback: (res) => {
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance.map((item) => {
                        return {
                            label: t(item.translationKey),
                            value: item.id,
                            raw: item
                        }
                    })
                }
            }
        })
    })

    useEffect(() => {
        if (!userStore.userData) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
        ])
    }, [userStore.userData]);

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    const colums = [
        {
            "title": "Category",
            "dataIndex": "category",
            "key": "category",
            "width": "30%"
        },
        {
            "title": "Measure",
            "dataIndex": "measure",
            "key": "measure",
            "width": "40%"
        },
        {
            "title": "Range",
            "dataIndex": "range",
            "key": "range",
            "width": "15%"
        },
        {
            "title": "Score",
            "dataIndex": "score",
            "key": "score",
            "width": "15%"
        }
    ];

    const data = [
        {
            "key": 1,
            "category": "Business & Service",
            "measure": "NPS",
            "range": "Net voldoende",
            "score": 50,
            "categorySortIndex": 10
        },
        {
            "key": 2,
            "category": "",
            "measure": "Offered Help",
            "range": "Zit goed",
            "score": 78,
            "categorySortIndex": 10
        },
        {
            "key": 3,
            "category": "",
            "measure": "Job match",
            "range": "Zit goed",
            "score": 62,
            "categorySortIndex": 10
        },
        {
            "key": 4,
            "category": "Candidate experience",
            "measure": "Feedback",
            "range": "Uitstekend",
            "score": 81,
            "categorySortIndex": 20
        },
        {
            "key": 5,
            "category": "",
            "measure": "Snelheid",
            "range": "Uitstekend",
            "score": 82,
            "categorySortIndex": 20
        },
        {
            "key": 6,
            "category": "",
            "measure": "Sfeer",
            "range": "Net voldoende",
            "score": 50,
            "categorySortIndex": 20
        },
        {
            "key": 7,
            "category": "",
            "measure": "Informatie",
            "range": "Net voldoende",
            "score": 50,
            "categorySortIndex": 20
        },
    ];

    return (
        <GuestRedirectLayout>

            <DashboardLayout data={dbGeneralData} type={"Business & Sales"}>
                <>

                    <div className="row">


                        <div className="flex flex-row align-items-center flex-nowrap mb-4" style={{ gap: 5 }}>

                            <Select
                                defaultValue={"all"}
                                className="w-100"
                                style={{ minWidth: 200 }}
                                options={[
                                    {
                                        label: "All recruiters",
                                        value: "all",
                                    },
                                    {
                                        label: "Jef Doe",
                                        value: "sales",
                                    },
                                    {
                                        label: "Vince de Groot",
                                        value: "operations",
                                    },
                                    {
                                        label: "Hilde van der Hoorn",
                                        value: "it",
                                    },
                                    {
                                        label: "Sander de Boer",
                                        value: "finance",
                                    },

                                ]}>
                            </Select>
                            {
                                phasesData &&
                                <Select
                                    className="w-100"
                                    defaultValue={null}
                                    style={{ minWidth: 300 }}
                                    loading={phasesDataIsLoading}
                                    options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                                />
                            }

                            <Select
                                placeholder="Select Date"
                                className="w-100"
                                defaultValue={null}
                                options={[
                                    {
                                        "label": t("business_dashboard_filter_select_all_dates"),
                                        "value": null
                                    },
                                    {
                                        "label": "Oct '23",
                                        "value": "Oct '23"
                                    },
                                    {
                                        "label": "Nov '23",
                                        "value": "Nov '23"
                                    },
                                    {
                                        "label": "Dec '23",
                                        "value": "Dec '23"
                                    },
                                    {
                                        "label": "Jan '24",
                                        "value": "Jan '24"
                                    },
                                    {
                                        "label": "Feb '24",
                                        "value": "Feb '24"
                                    },
                                    {
                                        "label": "Mar '24",
                                        "value": "Mar '24"
                                    },
                                    {
                                        "label": "Apr '24",
                                        "value": "Apr '24"
                                    },
                                    {
                                        "label": "May '24",
                                        "value": "May '24"
                                    },
                                    {
                                        "label": "Jun '24",
                                        "value": "Jun '24"
                                    },
                                    {
                                        "label": "Jul '24",
                                        "value": "Jul '24"
                                    },
                                    {
                                        "label": "Sep '24",
                                        "value": "Sep '24"
                                    },
                                    {
                                        "label": "Oct '24",
                                        "value": "Oct '24"
                                    },
                                    {
                                        "label": "Nov '24",
                                        "value": "Nov '24"
                                    }
                                ]}
                            />
                            <Select
                                defaultValue={"all"}
                                className="w-100"
                                style={{ minWidth: 200 }}
                                options={[
                                    {
                                        label: "All categories",
                                        value: "all",
                                    },
                                    {
                                        label: "Sales",
                                        value: "sales",
                                    },
                                    {
                                        label: "Operations",
                                        value: "operations",
                                    },
                                    {
                                        label: "IT",
                                        value: "it",
                                    },
                                    {
                                        label: "Finance",
                                        value: "finance",
                                    },

                                ]}>
                            </Select>
                        </div>
                    </div>
                    <Panel bodyStyle={{
                        paddingBottom: "15px"
                    }}>
                        <Table
                            showHeader={false}
                            locale={{
                                emptyText: <>
                                    <Empty description={t("business_extra_no_data")} />
                                </>
                            }}
                            pagination={false}
                            columns={colums}
                            dataSource={data.filter(fItem => fItem.categorySortIndex === 10).map((item) => {


                                const handleTag = (myItem) => {
                                    const item = myItem.toLowerCase();
                                    let color = null;

                                    if (item.includes("voldoende")) {
                                        color = "orange"
                                    } else if (item.includes("voldoende")) {
                                        color = "yellow"
                                    } else if (item.includes("zit goed")) {
                                        color = "lime"
                                    } else if (item.includes("uitstekend")) {
                                        color = "green"
                                    }
                                    else {
                                        color = "red"
                                    }

                                    return <Tag color={color} className="font-regular"  >{t(myItem)}</Tag>
                                }

                                return {
                                    ...item,
                                    range: handleTag(item.range)
                                }
                            })} />
                    </Panel>
                    <Panel
                        bodyStyle={{
                            height: 400,
                        }}
                    >
                        <DemoBarChart
                            data={{
                                labels: ['March', 'April', 'May', 'June', 'July', 'August', 'September'],
                                datasets: [
                                    {
                                        label: 'NPS - Average',
                                        data: [10, 12, 8, 12, 15, 10, 12],
                                        backgroundColor: ['rgba(255, 206, 86, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack1',
                                    },
                                    {
                                        label: 'NPS - Positive',
                                        data: [3, 5, 2, 10, 8, 12, 5],
                                        backgroundColor: ['rgba(102, 187, 106, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack1',
                                    },
                                    {
                                        label: 'NPS - Negative',
                                        data: [7, 3, 10, 5, 2, 8, 2],
                                        backgroundColor: ['rgba(255, 99, 132, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack1',
                                    },
                                    {
                                        label: 'Offered help - Average',
                                        data: [12, 10, 12, 8, 12, 15, 10],
                                        backgroundColor: ['rgba(255, 206, 86, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack2',
                                    },
                                    {
                                        label: 'Offered help - Positive',
                                        data: [5, 8, 12, 3, 5, 2, 10],
                                        backgroundColor: ['rgba(102, 187, 106, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack2',
                                    },
                                    {
                                        label: 'Offered help - Negative',
                                        data: [2, 12, 5, 10, 7, 3, 8],
                                        backgroundColor: ['rgba(255, 99, 132, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack2',
                                    },
                                    {
                                        label: 'Job match - Average',
                                        data: [15, 12, 10, 12, 8, 12, 15],
                                        backgroundColor: ['rgba(255, 206, 86, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack3',
                                    },
                                    {
                                        label: 'Job match - Positive',
                                        data: [10, 5, 8, 12, 3, 10, 12],
                                        backgroundColor: ['rgba(102, 187, 106, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack3',
                                    },
                                    {
                                        label: 'Job match - Negative',
                                        data: [7, 10, 2, 5, 12, 8, 3],
                                        backgroundColor: ['rgba(255, 99, 132, 1)'],
                                        borderWidth: 1,
                                        stack: 'stack3',
                                    },
                                ],
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    customLabelPlugin: {
                                        enabled: true,
                                    },
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    },
                                },
                            }}
                            plugins={[
                                {
                                    id: 'customLabelPlugin',
                                    afterDatasetsDraw(chart) {
                                        const {
                                            ctx,
                                            data,
                                        } = chart;

                                        data.datasets.forEach((dataset, datasetIndex) => {
                                            chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
                                                const label = dataset.label;
                                                if (!label.toLowerCase().includes("negative")) return
                                                const actualLabel = label.replace("- Negative", "");
                                                ctx.save();
                                                ctx.font = '10px Arial';
                                                ctx.fillStyle = 'black';
                                                ctx.opacity = '0.75';
                                                ctx.textAlign = 'center';
                                                ctx.fillText(
                                                    `${actualLabel}`,
                                                    bar.x,
                                                    bar.y - 10 // Position above the bar
                                                );
                                                ctx.restore();
                                            });
                                        });
                                    },
                                },
                            ]}
                        />

                    </Panel>
                    <Panel bodyStyle={{
                        paddingBottom: "15px"
                    }}>
                        <Table
                            showHeader={false}
                            locale={{
                                emptyText: <>
                                    <Empty description={t("business_extra_no_data")} />
                                </>
                            }}
                            pagination={false}
                            columns={colums}
                            dataSource={data.filter(fItem => fItem.categorySortIndex === 20).map((item) => {


                                const handleTag = (myItem) => {
                                    const item = myItem.toLowerCase();
                                    let color = null;
                                    if (item.includes("voldoende")) {
                                        color = "orange"
                                    } else if (item.includes("voldoende")) {
                                        color = "yellow"
                                    } else if (item.includes("zit goed")) {
                                        color = "lime"
                                    } else if (item.includes("uitstekend")) {
                                        color = "green"
                                    }
                                    else {
                                        color = "red"
                                    }
                                    return <Tag color={color} className="font-regular"  >{t(myItem)}</Tag>
                                }

                                return {
                                    ...item,
                                    range: handleTag(item.range)
                                }
                            })} />
                    </Panel>
                    <Panel
                        bodyStyle={{
                            height: 400,
                        }}
                    >
                        <DemoBarChart
                            data={businessExperienceChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: true
                                    },
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true
                                    }
                                },
                            }} />
                    </Panel>
                </>
            </DashboardLayout>

        </GuestRedirectLayout>
    );
}
const businessExperienceChartData = {
    labels: ['March', 'April', 'May', 'June', 'July', 'August', 'September'],
    datasets: [
        {
            label: 'Feedback',
            data: [10, 12, 8, 12, 15, 10, 12],
            backgroundColor: ['rgba(75, 192, 192, 1)'],
            borderWidth: 1,
            stack: 'stack1',
        },
        {
            label: 'Snelheid',
            data: [2, 12, 5, 10, 7, 3, 8],
            backgroundColor: ['rgba(153, 102, 255, 1)'],
            borderWidth: 1,
            stack: 'stack2',
        },
        {
            label: 'Sfeer',
            data: [15, 12, 10, 12, 8, 12, 15],
            backgroundColor: ['rgba(255, 159, 64, 1)'],
            borderWidth: 1,
            stack: 'stack3',
        },
        {
            label: 'Informatie',
            data: [7, 10, 2, 5, 12, 8, 3],
            backgroundColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1,
            stack: 'stack4',
        },
    ]
};
