import { useQuery } from "@tanstack/react-query";
import { Empty, Table, Tag } from "antd";
import Panel from "components/modules/panel/panel";
import { adminRoleId } from "components/utils/methods/AppMethods";
import { getDateInFormat } from "components/utils/methods/MyMethods";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";

export default function CandidateBrandExperiencePanel(props) {
    const userStore = useUserStore();
    const [indexCategories, setIndexCategories] = useState([]);
    const isAdmin = userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;

    const { data, isLoading, isError, refetch, isRefetching } = useGetDbCandidateBrandExperienceScores({
        businessId: userStore.companyData.id,
        filterUserId: props.filterUserId,
        filterPhaseId: props.filterPhaseId,
        filterDate: props.filterDate,
        filterJob: props.filterJob,
        setIndexCategories, isAdmin, queryKey: props.queryKey
    });

    useEffect(() => {
        setIndexCategories([]);
        refetch();
    }, [props.filterUserId, props.filterPhaseId, props.filterDate, props.filterJob, userStore.clientData.language.isoCode])

    return <>
        {
            !data || !indexCategories || indexCategories.length === 0 || isRefetching || isLoading ? null :
                !data.data || !data.columns ? null :
                    <div className="">
                        {
                            indexCategories && indexCategories.filter((item) => props.catFilter ? props.catFilter(item) : true).map((item, index) => {
                                return <Fragment key={index}>
                                    <Panel bodyStyle={{
                                        paddingBottom: "15px"
                                    }}>
                                        <Table
                                            showHeader={false}
                                            locale={{
                                                emptyText: <>
                                                    <Empty description={t("business_extra_no_data")} />
                                                </>
                                            }}
                                            pagination={false}
                                            columns={data.columns}
                                            dataSource={data.data.filter((filterItem) => (filterItem.categorySortIndex === item))} />
                                    </Panel>
                                </Fragment>
                            })
                        }
                    </div>
        }
    </>
}
export function DemoCandidateBrandExperiencePanel(props) {
    const userStore = useUserStore();
    const [indexCategories, setIndexCategories] = useState([10]);
    const isAdmin = userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;

    const { data, isLoading, isError, refetch, isRefetching } = useGetDbCandidateBrandExperienceScores({
        businessId: "d713b98f-aec8-4329-95d0-465ab6b72385", // BRUSSELS AIR DEMO
        filterUserId: props.filterUserId,
        filterPhaseId: props.filterPhaseId,
        setIndexCategories, isAdmin, isDemo: true
    });

    useEffect(() => {
        refetch();
    }, [props.filterUserId, props.filterPhaseId, userStore.clientData.language.isoCode])

    return <>
        {
            !data || !indexCategories || indexCategories.length === 0 ? null :
                !data.data || !data.columns ? null :
                    <div className="">
                        {
                            [10].filter((item) => props.catFilter ? props.catFilter(item) : true).map((item, index) => {
                                return <Fragment key={index}>
                                    <Panel bodyStyle={{
                                        paddingBottom: "15px"
                                    }}>
                                        <Table
                                            showHeader={false}
                                            locale={{
                                                emptyText: <>
                                                    <Empty description={t("business_extra_no_data")} />
                                                </>
                                            }}
                                            pagination={false}
                                            columns={data.columns}
                                            dataSource={data.data.filter((filterItem) => (filterItem.categorySortIndex === item))} />
                                    </Panel>
                                </Fragment>
                            })
                        }
                    </div>
        }
    </>
}

const useGetDbCandidateBrandExperienceScores = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    const key = props.queryKey ? `getDbCandidateBrandExperienceScoreData-${props.queryKey}` : `getDbCandidateBrandExperienceScoreData`;

    return useQuery({
        queryKey: [key],
        cacheTime: 0,
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/scores`,
            body: {
                CeKey: props.ceKey || null,
                BusinessId: props.businessId,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
                FilterDate: props.filterDate ? getDateInFormat(props.filterDate, 'YYYY-MM-DD') : null,
                FilterJobId: props.filterJob || null
            },
            callback: (res) => {
                const columns = [{
                    title: "Category",
                    dataIndex: "category",
                    key: "category",
                    width: "30%"
                }, {
                    title: "Measure",
                    dataIndex: "measure",
                    key: "measure",
                    width: "40%"
                }, {
                    title: "Range",
                    dataIndex: "range",
                    key: "range",
                    width: "15%"
                }, {
                    title: "Score",
                    dataIndex: "score",
                    key: "score",
                    width: "15%"
                }];

                const data = [];
                const indexCategories = [];

                const handleTag = (item) => {
                    let color = null;
                    if (item.rangeTranslationKey.includes("clusterscore_a")) {
                        color = "red"
                    } else if (item.rangeTranslationKey.includes("clusterscore_b")) {
                        color = "orange"
                    } else if (item.rangeTranslationKey.includes("clusterscore_c")) {
                        color = "yellow"
                    } else if (item.rangeTranslationKey.includes("clusterscore_d")) {
                        color = "lime"
                    } else if (item.rangeTranslationKey.includes("clusterscore_e")) {
                        color = "green"
                    }

                    return <Tag color={color} className="font-regular"  >{t(item.rangeTranslationKey)}</Tag>
                }

                const sortedData = res.data.instance.sort((a, b) => {
                    if (a.categoryTranslationKey.includes("_experience")) {
                        return b.categoryTranslationKey.includes("_experience")
                            ? a.measureTranslationKey.localeCompare(b.measureTranslationKey)
                            : -1;
                    } else {
                        return b.categoryTranslationKey.includes("_experience")
                            ? 1
                            : a.categoryTranslationKey.localeCompare(b.categoryTranslationKey);
                    }
                });

                sortedData.forEach((element, i) => {
                    if (!propsFn.isAdmin && element.categoryTranslationKey.includes("jobquestioncluster_online")) return

                    else data.push({
                        key: i + 1,
                        category: data.find((item) => item.category === t(element.categoryTranslationKey)) ? '' : t(element.categoryTranslationKey),
                        measure: t(element.measureTranslationKey),
                        range: handleTag(element),
                        score: element.score,
                        categorySortIndex: element.categorySortIndex
                    })

                    if (propsFn.setIndexCategories && !indexCategories.includes(element.categorySortIndex)) {
                        indexCategories.push(element.categorySortIndex)
                    }
                });

                if (propsFn.setIndexCategories) propsFn.setIndexCategories(indexCategories)
                console.log({ data, columns });

                return { data: data, columns: columns }
            }
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    })
}