import { Collapse } from 'antd';

export default function AccordionContent(props) {

    const { Panel } = Collapse;

    return (
        <div className='my-2'>
            <Collapse
                defaultActiveKey={props.defaultActiveKey ? [props.defaultActiveKey] : []}
                accordion
                expandIconPosition="end"
                className={`bg-white ${props.className}`}

                items={[
                    {
                        key: '1',
                        label: props.title,
                        children: props.content || props.children,
                    },
                ]}
            >
            </Collapse>
        </div>
    )
}