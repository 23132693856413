import { BlobServiceClient } from '@azure/storage-blob';
import i18next, { t } from 'i18next';
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { isValid } from './MyMethods';

// APPMETHODS: Methods that can only be used in this app because of the components functionality

export const dateFormat = "DD/MM/YYYY";

export const adminRoleId = "cac9fd97-8842-46bf-bfc7-34c88b72c6b8";
export const freePlanId = "3cb3c932-9d09-4a58-aca0-266919db8641";

export const handleUploadPicture = async (file, maxSize, maxWidth, maxHeight, filePath) => {
    let finalUrl = null;

    try {
        //Limit image type
        if (!(file.type === "image/jpeg" || file.type === "image/png")) {
            toast.error(t("business_extra_allowed_filetype_invalid"));
            return null;
        }

        //Limit upload size
        if (!((file.size / 1024 / 1024) < maxSize)) {
            toast.warn(`${t("business_extra_allowed_filesize2")} ${maxSize} Kb.`); //`${t("error_image_filesize")} ${10}${t("megabyte")}`
            return null;
        }

        //Setup promise to draw image
        const img = new Image();
        img.src = URL.createObjectURL(file);

        await new Promise((resolve) => {
            img.onload = resolve;
        });

        let newWidth = img.width;
        let newHeight = img.height;

        //Hold on to aspect ratio but limit resolution
        if (maxWidth > 0 ? newWidth > maxWidth : false) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
        }
        if (maxHeight > 0 ? newHeight > maxHeight : false) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
        }

        //Setup fake image to retrieve new resolution image
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        //Upload to Azure Blob directly using SAS
        const blobServiceClient = new BlobServiceClient("https://wisepeoplestorage.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-01-01T01:36:24Z&st=2024-04-15T16:36:24Z&spr=https&sig=nr%2Ff%2FqxcRjWa1iOBKgBqr2bB62IhjLCTR3kjz3OmW%2FM%3D");
        const byteArray = Uint8Array.from(Array.from(atob(canvas.toDataURL(file.type).split(',')[1]), char => char.charCodeAt(0)));

        let result = await blobServiceClient
            .getContainerClient("assets")
            .getBlockBlobClient(`${filePath}/${uuidv4()}.${file.type === "image/jpeg" ? "jpg" : "png"}`)
            .uploadData(byteArray.buffer, { blobHTTPHeaders: { blobContentType: file.type } });

        finalUrl = result?._response?.request?.url?.split('?')[0];

        if (!finalUrl) {
            throw new Error("Failed upload Azure");
        }
    } catch (error) {
        console.error(error);
    }

    return finalUrl;
};

// Handles reducer for tags || tags list looks like: [[ID [can be null], VALUE],...] || 
// ACTION HAS: 
//  target: key list, 
//  list: list values, 
//  ?id: id select list item
//  value: new item, 
//  event: event input, 
//  ?isList: select list component, 
//  ?threshold

export function handleReducerTags(state, action) {
    action.payload.event.preventDefault();
    var tagsArray = [];
    // if ($("#" + (action.payload.event).target.id + " option:selected").attr("id") === undefined && action.payload.isList) { return { state } }
    if (!isValid([action.payload.list], [undefined, null])) {
        if (action.payload.isList) {
            tagsArray = [[action.payload.id, action.payload.value]];
        } else {
            tagsArray = [[null, action.payload.value]];
        }
    } else {
        tagsArray = action.payload.list;
        if ((action.payload.list).length >= action.payload.threshold) {
            return;
        }

        // Is a list component (so the value has an id)
        if (action.payload.isList) {
            tagsArray = [...tagsArray, [action.payload.id, action.payload.value]];

        } else {
            tagsArray = [...tagsArray, [null, action.payload.value]];
        }
    }
    action.payload.event.target.value = "";
    return {
        ...state,
        [action.payload.target]: tagsArray
    };
}
// Handles reducer for tag remove
export function handleReducerRemoveTag(state, action) {
    (action.payload.event).preventDefault();
    if (!action.payload.isList) {
        var newAllTags = (action.payload.list).filter(tag => tag[1].toLowerCase() !== (action.payload.event).target.getAttribute("value").toLowerCase());
        return {
            ...state,
            [action.payload.target]: newAllTags
        };
    } else {
        var newAllTags = (action.payload.list).filter(item => item[0] !== (action.payload.event).target.getAttribute("id").toLowerCase());

        return {
            ...state,
            [action.payload.target]: newAllTags
        };
    }
}

// handle the login data from call -> methods used in rememberme & login
export function handleUserLoginData(props) {
    try {
        const data = props.data;

        // COMPANYDATA - add to store
        var companyData = data.companyData.instance;
        companyData.subscriptionData = data.companySubscriptionData.instance; // wise subscription details company
        // vince zei dat de subscriptienaam toch nooit gaat vertaald worden dus hierbij moet ik niet meer de translation ophalen :o
        companyData.subscriptionData.name = data.companySubscriptionData.instance.string1; // logical access to name of subscription
        companyData.locationData = data.locationData.instance; // location of company
        props.userStore.replaceData("companyData", companyData);

        // USERDATA - add to store
        var userData = { ...data.userData.instance };
        // handle permission data
        var permissions = [];
        data.permissionsData.instance.forEach(item => permissions.push(item.permission))
        userData.permissionsData = permissions; // user permissions

        userData.languageData = data.userLanguageData.instance; // language from user
        userData.roleData = data.roleData.instance; // role of user in company
        userData.roleData.extra = data.userRoleData.instance; // extra role details
        props.userStore.replaceData("userData", userData);

        // CLIENTDATA - update the store
        props.userStore.changeClientLoggedIn(true);
        props.userStore.changeClientLanguage({
            isoCode: data.userLanguageData.instance.isoCode.toLowerCase(),
            text: data.userLanguageData.instance.name,
            id: data.userLanguageData.instance.id
        });
        i18next.changeLanguage(data.userLanguageData.instance.isoCode.toLowerCase());

        // FLAGDATA - add to store
        const flagData = data.flagData.instance
        props.userStore.replaceData("flagData", flagData);

        // LOCALSTORE ITEMS
        localStorage.setItem("userId", data.userData.instance.id);
        if (data.tokenData) {
            localStorage.setItem("cToken", data.tokenData.token);
        }
        return;

    } catch (error) {
        throw Error(error)
    }
}

export function hasUserPermission(userStore, permissionTag) {
    if (!userStore.userData) { return false }
    return (userStore.userData.permissionsData).filter(permission => permission === permissionTag).length > 0 ? true : false;
}


// if select has translation then you can use this handler if the object has the object.translations in them
export function handleTranslationLabel(props) {
    var newList = [];
    if (props.list) {
        props.list.forEach(listItem => {
            var newListItem = { ...listItem };
            newListItem.label = (listItem.translations).filter(translation => translation.languageId === props.langId)[0].text;
            newList.push(newListItem)
        })
    }
    return newList
}

// used to check if a antd form item has an empty field (& with spaces) -> else give error on submit
export function validatorEmptyFormItem(name) {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (getFieldValue(name).trim().length === 0) {
                return Promise.reject(false);
            }
            return Promise.resolve();
        },
    })
}

export function checkIsPremiumPlan(plan) {
    const selectedPlan = parseInt(plan);
    return selectedPlan > 10
}

export const useIsPremiumPlan = (userStore) => {
    return checkIsPremiumPlan(userStore.companyData.subscriptionData.int1)
}
export const isPremiumSubscriptionPlan = (userStore) => {
    return checkIsPremiumPlan(userStore.companyData.subscriptionData.int1)
}