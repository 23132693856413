import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Input, Select, Spin, Tag, Tooltip } from "antd";
import axios from "axios";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import useEditTeamRole from "components/requests/company/useEditTeamRole";
import useGetCompanyTeam from "components/requests/company/useGetCompanyTeam";
import useGetRoleOptions from "components/requests/data/useGetRoleOptions";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Loading from "../../components/animations/loading";
import NoData from '../../components/layouts/no-data/nodata';
import ConfirmDeleteTeamOverlay from "../../components/layouts/overlays/confirm-delete-team";
import InviteTeamOverlay from "../../components/layouts/overlays/invite-team";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import { InfoCircleOutlined } from '@ant-design/icons'
import LayoutCompany from "./layout-company";
import { toast } from "react-toastify";
import { AlertOctagon, AlertTriangle, Check, Loader } from "react-feather";
import { freePlanId } from "components/utils/methods/AppMethods";

export default function CompanyTeamPage2(props) {
    // # STORES
    const userStore = useUserStore();

    // # UIILS
    const maxEmailDomains = 100;
    const maxCharMail = 25;
    const maxCharJob = 15;

    // # CLIENT STATES
    const [currentTeam, setCurrentTeam] = useState(false);
    const [filteredTeam, setFilteredTeam] = useState(null);
    const [filterSearch, setFilterSearch] = useState(null);
    const [overlayInvite, setOverlayInvite] = useState(false);
    const [overlayDelete, setOverlayDelete] = useState(false);
    const [selectDelete, setSelectDelete] = useState(null);
    const [allRoles, setAllRoles] = useState(false);
    const [allDomains, setAllDomains] = useState([]);
    const [inputDomainTag, setInputDomainTag] = useState("");
    // PERMISSIONS
    const [PM_CompanyTeamView, setPM_CompanyTeamView] = useState(false);
    const [PM_CompanyTeamDelete, setPM_CompanyTeamDelete] = useState(false);
    const [PM_CompanyTeamEdit, setPM_CompanyTeamEdit] = useState(false);
    const [PM_CompanyTeamInvite, setPM_CompanyTeamInvite] = useState(false);
    const [PM_CompanyEmailRestrictionEdit, setPM_CompanyEmailRestrictionEdit] = useState(false);
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    // # SERVER STATES
    const { refetch: companyTeamRefetch, isLoading: companyTeamIsLoading, isError } = useGetCompanyTeam({ setData: setCurrentTeam })
    const { } = useGetRoleOptions({ setData: setAllRoles });
    const { refetch: companyEmailDomainsRefetch, isRefetching: companyEmailDomainsIsRefetching, isLoading: companyEmailDomainsIsLoading } = useGetCompanyEmailDomains({ setData: setAllDomains });
    const mutateAddEmailDomain = useAddEmailDomain({ getData: companyEmailDomainsRefetch });
    const mutateRemoveEmailDomain = useRemoveEmailDomain({ getData: companyEmailDomainsRefetch });
    const mutateTeamRole = useEditTeamRole({ getData: companyTeamRefetch })


    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_TEAM", setPM_CompanyTeamView);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_TEAM", setPM_CompanyTeamDelete);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_TEAM", setPM_CompanyTeamEdit);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_TEAM", setPM_CompanyTeamInvite);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_TEAM_REQUIREMENTS", setPM_CompanyEmailRestrictionEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);

    function textToClipboard(text) {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        toast.success(t("business_extra_copied_to_clipboard"))
    }

    function handleInputDomainsOnEnter(e) {
        var canContinue = true;
        if (!PM_CompanyEmailRestrictionEdit) { return }
        if ((e.target.value).trim().length === 0) { return }
        if (allDomains !== null) {
            if ((allDomains).length >= maxEmailDomains) {
                canContinue = false;
                return;
            }
        }
        if (allDomains !== null) {
            allDomains.map(item => {
                if (item[1] === e.target.value) { return canContinue = false; }
            })
        }

        if (!canContinue) { return }

        mutateAddEmailDomain.mutate({ tag: e.target.value })
        setInputDomainTag("");
    }

    function getCurrentRoleValue(id) {
        if (!allRoles) { return }
        if (allRoles.filter(role => role.id === id).length === 0) { return }
        return allRoles.filter(role => role.id === id)[0].value;
    }

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (filterSearch === null) { return }
        if (currentTeam === null) { return }
        if (currentTeam === undefined) { return }
        if (currentTeam === false) { return }
        var newRes = currentTeam.filter((item, i) => {
            var fullname = item.firstName + " " + item.lastName;
            return fullname.toLowerCase().includes(filterSearch.toLowerCase());
        }
        );
        setFilteredTeam(newRes);
    }, [filterSearch]);

    useEffect(() => {
        setFilteredTeam(currentTeam);
    }, [currentTeam]);

    useEffect(() => {
        setTimeout(() => {
            companyTeamRefetch();
        }, [10])
    }, [overlayDelete, overlayInvite]);

    return (
        <GuestRedirectLayout redirectSmallSubsciption>
            <LayoutCompany
                title={t("business_company_team_subtitle")}
                subtitle={t("business_company_team_subtitle")}
            >
                <>
                    <InviteTeamOverlay overlayState={overlayInvite} setOverlay={setOverlayInvite} />
                    <ConfirmDeleteTeamOverlay
                        overlayState={overlayDelete}
                        setOverlay={setOverlayDelete}
                        getData={companyTeamRefetch}
                        item={selectDelete === null ? "-" : selectDelete} />
                    {
                        !PM_CompanyTeamView ? <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                            <div className="mt-4">
                                <div>
                                    <div>
                                        {
                                            !hasPermission(userStore.userData.permissionsData, "PM_VIEW_TEAM_REQUIREMENTS") ? null :
                                                <div className="card mb-4">
                                                    <div className="container">
                                                        <Form
                                                            layout="vertical"
                                                        >
                                                            <Form.Item
                                                                name="companyTags"
                                                                label={t("business_company_team_panel_domains_allowed")}
                                                            >
                                                                <>
                                                                    <ReactTooltip className="tooltip" place="bottom" />
                                                                    <Input
                                                                        disabled={!PM_CompanyEmailRestrictionEdit}
                                                                        value={inputDomainTag}
                                                                        onChange={(e) => setInputDomainTag(e.target.value)}
                                                                        placeholder={t("business_extra_placeholder_domain")}
                                                                        onPressEnter={handleInputDomainsOnEnter}
                                                                        suffix={
                                                                            <Tooltip overlayInnerStyle={{ fontSize: "14px" }} title={t("business_tooltip_company_team_panel_domains_allowed")}>
                                                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                                            </Tooltip>
                                                                        }
                                                                    />
                                                                    {
                                                                        companyEmailDomainsIsRefetching || companyEmailDomainsIsLoading ? <Spin size="small" className="mt-2" /> :
                                                                            allDomains.length === 0 ? null :
                                                                                <div className="mt-2">
                                                                                    {
                                                                                        allDomains.map((tag, i) => {
                                                                                            return <Fragment key={i}>
                                                                                                <Tag closable={PM_CompanyEmailRestrictionEdit} className="large" onClose={(e) => !PM_CompanyEmailRestrictionEdit ? null : mutateRemoveEmailDomain.mutate({ id: tag.id })}>{tag.value}</Tag>
                                                                                            </Fragment>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                    }
                                                                </>
                                                            </Form.Item>
                                                        </Form>
                                                    </div>
                                                </div>
                                        }
                                        <div className="card card-scroll">
                                            <div className="container">
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="panel-actions mb-2">
                                                        <div className="d-inline-block mb-2 mr-2">
                                                            <Input
                                                                onChange={(e) => setFilterSearch(e.target.value.toLowerCase())}
                                                                placeholder={t("business_main_search")}
                                                                prefix={<span className="icon icon-search icon-nospace mr-1" />}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        !PM_CompanyTeamInvite ? null :
                                                            <div>
                                                                <Link to="#" onClick={() => setOverlayInvite(true)} className="btn btn-lg btn-primary d-inline-block">
                                                                    {t("business_company_team_panel_members_invite")}
                                                                </Link>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="scroll">
                                                <div id="manage-table" className="container">
                                                    <div className={"manage-table team-table"}>
                                                        <div className="head">
                                                            <span>
                                                                {t("business_main_name")}
                                                            </span>
                                                            {/* <span>
                                                                {t("business_main_job")}
                                                            </span> */}
                                                            <span>
                                                                {t("business_main_email")}
                                                            </span>
                                                            <span>
                                                                {t("business_main_phone_short")}
                                                            </span>
                                                            <div className="actions">
                                                                <span>
                                                                    {t("business_main_role")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ReactTooltip place="bottom" className="tooltip" />

                                                        {
                                                            companyTeamIsLoading ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                                isError ? <NoData error /> :
                                                                    filteredTeam === undefined ? null :
                                                                        filteredTeam === null ? <div className="my-4"><NoData wise="cross" /></div> :
                                                                            filteredTeam.length === 0 ? <div className="my-4"><NoData wise="search" /></div> :
                                                                                Array.from(filteredTeam).map((item, i) => (

                                                                                    <div key={i} id={"team-" + item.userId} className="item">
                                                                                        <span
                                                                                            className="pointer flex align-items-center"
                                                                                            data-tip={t("business_extra_copy_to_clipboard")}
                                                                                            onClick={() => { textToClipboard(item.firstName + " " + item.lastName); }}>
                                                                                            <span className="mr-2">
                                                                                                {handleStatusIcon(item.status)}
                                                                                            </span>
                                                                                            <span className="font-special">
                                                                                                {item.firstName === null || item.firstName === undefined || item.firstName === " " ? "-" : item.firstName} {item.lastName === null || item.lastName === undefined || item.lastName === " " ? "" : item.lastName}
                                                                                            </span>
                                                                                        </span>
                                                                                        {/* <span className="pointer" data-tip={t("business_extra_copy_to_clipboard")} onClick={() => { textToClipboard(item.functionText); }} title={item.functionText === null || item.functionText === undefined ? "-" : item.functionText}>
                                                                                            {
                                                                                                item.functionText === null || item.functionText === undefined ? "-" :
                                                                                                    item.functionText.length <= maxCharJob ?
                                                                                                        item.functionText :
                                                                                                        item.functionText.substring(0, maxCharJob) + "..."
                                                                                            }
                                                                                        </span> */}
                                                                                        <span className="pointer font-special" data-tip={t("business_extra_copy_to_clipboard")} onClick={() => { textToClipboard(item.email); }} title={item.email === null || item.email === undefined ? "-" : item.email}>
                                                                                            {
                                                                                                item.email === null || item.email === undefined ? "-" :
                                                                                                    item.email.length <= maxCharMail ?
                                                                                                        item.email :
                                                                                                        item.email.substring(0, maxCharMail) + "..."
                                                                                            }</span>
                                                                                        <span className="pointer font-special" data-tip={t("business_extra_copy_to_clipboard")} onClick={() => { textToClipboard(item.phone); }} >{item.phone === null || item.phone === undefined ? "-" : item.phone}</span>

                                                                                        {
                                                                                            PM_CompanyTeamEdit ?
                                                                                                <div className="actions">
                                                                                                    {

                                                                                                        userStore.companyData.subscriptionData && userStore.companyData.subscriptionData.guid1 === freePlanId ? "-" :
                                                                                                            <span className="role">
                                                                                                                <Select
                                                                                                                    options={allRoles}
                                                                                                                    value={getCurrentRoleValue(item.roleId)}
                                                                                                                    bordered={false}
                                                                                                                    onSelect={(value, option) => mutateTeamRole.mutate({ teamUserId: item.userId, roleId: option.id })}
                                                                                                                    disabled={item.userId === userStore.userData.id ? true : !PM_CompanyTeamEdit}
                                                                                                                />
                                                                                                            </span>
                                                                                                    }

                                                                                                    {
                                                                                                        item.userId === userStore.userData.id ? null :
                                                                                                            <a href={"mailto:" + item.email}>
                                                                                                                <span className="default icon-mail-full fs-small icon" />
                                                                                                            </a>
                                                                                                    }
                                                                                                    {
                                                                                                        item.userId === userStore.userData.id ? null :
                                                                                                            !PM_CompanyTeamDelete ? null :
                                                                                                                <span onClick={() => { setOverlayDelete(true); setSelectDelete([item.userId, item.email]) }} className="default icon-delete icon" />
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div className="actions">
                                                                                                    {
                                                                                                        userStore.companyData.subscriptionData && userStore.companyData.subscriptionData.guid1 === freePlanId ? "-" :
                                                                                                            <span className="role">
                                                                                                                <Select
                                                                                                                    options={allRoles}
                                                                                                                    value={getCurrentRoleValue(item.roleId)}
                                                                                                                    disabled
                                                                                                                    bordered={false}
                                                                                                                />
                                                                                                            </span>
                                                                                                    }
                                                                                                    {
                                                                                                        item.userId === userStore.userData.id ? null :
                                                                                                            <a href={"mailto:" + item.email}>
                                                                                                                <span className="default icon-mail-full fs-small icon" />
                                                                                                            </a>
                                                                                                    }
                                                                                                    {
                                                                                                        item.userId === userStore.userData.id ? null :
                                                                                                            !PM_CompanyTeamDelete ? null :
                                                                                                                <span onClick={() => { setOverlayDelete(true); setSelectDelete([item.userId, item.email]) }} className="default icon-delete icon" />
                                                                                                    }
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}

const useAddEmailDomain = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateAddEmailDomain"],
        mutationFn: (propsFn) => axios.post("company/domain/email/insert", {
            Extension: propsFn.tag,
            CompanyId: userStore.companyData.id,
            UserId: userStore.userData.id
        }),
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                if (props.getData) {
                    setTimeout(() => {
                        props.getData()
                    }, (200));
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useRemoveEmailDomain = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateRemoveEmailDomain"],
        mutationFn: (propsFn) => axios.post("company/domain/email/remove", {
            Id: propsFn.id,
            // CompanyId: userStore.companyData.id,
            UserId: userStore.userData.id
        }),
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                if (props.getData) {
                    setTimeout(() => {
                        props.getData()
                    }, (200));
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useGetCompanyEmailDomains = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getCompanyEmailDomains"],
        queryFn: () => axios.post("company/domains/email", {
            CompanyId: userStore.companyData.id,
            UserId: userStore.userData.id
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                var newItems = [];
                Array.prototype.forEach.call(data, function (item, i) {
                    return newItems = [...newItems, { id: item.id, value: item.extension, data: item }];
                });
                props.setData(newItems);
            },
            customCallback: () => {
                switch (res.data.status) {
                    case 290:
                        props.setData([]);
                        break;
                    default:
                        break;
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}


function handleStatusIcon(status) {
    const size = 16;
    const tooltipProps = { title: t(status), placement: "left" }
    switch (status) {
        case "business_main_verified":
            return <Tooltip {...tooltipProps} ><Check color="green" size={size} /></Tooltip>;
        case "business_main_unverified":
            return <Tooltip {...tooltipProps} ><AlertTriangle color="orange" size={size} /></Tooltip>;
        case "business_main_pending":
            return <Tooltip {...tooltipProps} ><Loader size={size} /></Tooltip>;
        default:
            return <Tooltip {...tooltipProps} ><AlertOctagon color="red" size={size} /></Tooltip>;
    }
}