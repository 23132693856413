import { useQuery } from "@tanstack/react-query";
import { getDateInFormat } from "components/utils/methods/MyMethods";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import moment from "moment";

export const useGetDbExperienceScale = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    return useQuery({
        queryKey: [props.key || 'getDbExperienceScoreScale'],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/scores/experiences`,
            // route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/experiences`,
            body: {
                Category: props.category || 'Information',
                BusinessId: userStore.companyData.id,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
                FilterDate: props.filterDate ? getDateInFormat(props.filterDate, 'YYYY-MM-DD') : null,
            }
        }),
        refetchOnWindowFocus: false,
        enabled: props.enabled || false,
    })
}