import { Chart, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2"
import { v4 as uuidv4 } from 'uuid';

Chart.register(...registerables);

export default function DemoBarChart(props) {
    const id = uuidv4();

    if (!props.data) return
    if (!props.options) return

    return <>
        <Bar
            id={id}
            data={props.data}
            options={props.options}
            plugins={props.plugins || []}
        />
    </>
}