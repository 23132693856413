import { Modal } from "antd";
import BarChart from "components/layouts/charts/barchart";
import LineChart from "components/layouts/charts/linechart";
import NoData from "components/layouts/no-data/nodata";
import Panel, { defaultModalContent } from "components/modules/panel/panel";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";

export default function ScoreEvolutionPanel(props) {
    // # CLIENT STATES
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(defaultModalContent);
    const [data, setData] = useState();

    // ? Handle raw data and create usable data structure accepted for barchart component
    useEffect(() => {
        if (!props.data) { return }
        if (!props.data[0]) { return }
        if (!props.data[0].data) { return }
        const chartData = [];

        props.data[0].data.forEach(item => {

            const date = moment({ year: item.yearIndex, month: item.monthIndex }); // Adjust month to 0-based index
            const formattedDate = date.format('MMM YY'); // Format like "Jun 23"

            const existingEntry = chartData.find(entry => entry.date === formattedDate);
            if (existingEntry) {
                // existingEntry[categoryTranslation] = item.count;
                console.log("double entry found", existingEntry)
            } else {
                const color = item.score >= 80 ? "hsl(175, 75%, 38%)" : item.score < 80 && item.score > 60 ? "hsl(50, 100%, 67%)" : "hsl(0, 84%, 47%)";
                const entry = {
                    date: formattedDate,
                    score: item.score,
                    scoreColor: color
                };
                chartData.push(entry);
            }
        });
        setData(chartData);

    }, [props.data])


    return <>
        <Modal
            closable
            onCancel={() => setIsModalOpen(false)}
            title={modalContent.titleKey ? t(modalContent.titleKey) : modalContent.title}
            open={isModalOpen}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
        >
            {typeof modalContent.children === "function" ? modalContent.children() : modalContent.children}
        </Modal>
        <Panel
            {...props.panelProps}
            title={<>{t("business_dashboard_panel_evolution_average_score_title")}</>}
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
        >
            {
                !data ? <NoData wise="sad" className="my-5" /> :
                    <BarChart
                        maxWidth={1370}
                        xAxis={t("business_dashboard_date")}
                        yAxis={t("business_dashboard_score")}
                        minYscale={"0"}
                        keys={["score"]}
                        data={data}
                        hideLegends
                    />
            }
        </Panel>
    </>
}


export const howToImproveScoreEvolutionModalContent = {
    titleKey: "business_dashboard_panel_average_score_how_it_works_title",
    children: () => <>
        <p>
            {t("business_dashboard_panel_average_score_how_it_works_content")}
        </p>
    </>
};