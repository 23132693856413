import { t } from "i18next"
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react';
import { useUserStore } from "components/utils/stores/userStore";
import { hasPermission } from "components/utils/methods/MyMethods";
import { freePlanId } from "components/utils/methods/AppMethods";

export default function LayoutCompany(props) {

    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);
    const [PM_ProcedureEdit, setProcedureEdit] = useState(false);

    // # UTILS
    function getPlan() {
        if (!userStore.companyData) { return }
        return userStore.companyData.subscriptionData.name;
    }


    useEffect(() => {
        if (!userStore.userData) { return }
        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_PROCEDURES", setProcedureEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);

    return <>
        <div id="layout-company">
            <div className="sidebar-container profile-menu">
                <div className="sidebar-menu">
                    <div className="sidebar-menu-wrapper">
                        <div className="menu-header">
                            <div className="icon icon-company">
                                {t("business_main_company")}
                            </div>
                        </div>
                        <div className="menu-content pb-10">
                            <div className="menu-content-wrapper">
                                <Link to="/company/info">
                                    <div className={`menu-item ${window.location.pathname.includes("/company/info") ? "active" : ""}`}>
                                        {t("business_company_sidebar_general_title")}
                                    </div>
                                </Link>
                                {
                                    !hasPermission(userStore.userData.permissionsData, "PM_VIEW_TEAM") ? null :
                                        <Link to="/company/team">
                                            <div className={`menu-item ${window.location.pathname.includes("/company/team") ? "active" : ""}`}>
                                                {t("business_company_sidebar_team_title")}
                                            </div>
                                        </Link>
                                }
                                {/* {
                                    PM_PromiseView === false ? null :
                                        <Link to="/company/promise">
                                            <div className={`menu-item ${window.location.pathname.includes("/company/promise") ? "active" : ""}`}>
                                                {t("business_company_promise_title")}
                                            </div>
                                        </Link>
                                } */}
                                {
                                    PM_ProcedureView === false ? null :
                                        PM_ProcedureEdit === false ? null :
                                            <Link to="/company/procedure">
                                                <div className={`menu-item ${window.location.pathname.includes("/company/procedure") ? "active" : ""}`}>
                                                    {t("business_company_sidebar_process_title")}
                                                </div>
                                            </Link>
                                }
                                {
                                    PM_RecruitmentHistoryView === false ? null :
                                        <Link to="/company/recruitment-history">
                                            <div className={`menu-item ${window.location.pathname.includes("/company/recruitment-history") ? "active" : ""}`}>
                                                {t("business_company_sidebar_history_title")}
                                            </div>
                                        </Link>
                                }
                                {

                                    userStore.companyData.subscriptionData && userStore.companyData.subscriptionData.guid1 === freePlanId ? null :
                                        <Link to="/company/share">
                                            <div className={`menu-item ${window.location.pathname.includes("/company/share") ? "active" : ""}`}>
                                                {t("business_company_sidebar_share_title")}
                                            </div>
                                        </Link>
                                }
                                {/* <Link to="/company/plans">
                                    <div className={`menu-item ${window.location.pathname.includes("/company/plans") ? "active" : ""}`}>
                                        {t("business_company_sidebar_plans_title")}
                                    </div>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sidebar-content container">
                    <div className="page-content my-10 mb-15">
                        <div className="content-header mb-4">

                            <div className="d-flex justify-content-between w-100 flex-wrap">
                                <h1 className="title pr-4 desktop-lg-only">{props.title}</h1>
                                <div onClick={() => userStore.toggleClientDataMobileMenuOverlay()} className="lg-only menu-title icon icon-arrow2-left">
                                    <h1>{props.title}</h1>
                                </div>
                                {
                                    !props.showPlan ? null :
                                        <div className="mb-3">
                                            <span className="pr-2">
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="11.5" cy="12.125" r="8.625" fill="white" />
                                                    <path d="M23 11.4062L20.4491 8.54152L20.8045 4.75268L17.0305 3.91071L15.0545 0.625L11.5 2.12411L7.94545 0.625L5.96955 3.90045L2.19545 4.73214L2.55091 8.53125L0 11.4062L2.55091 14.271L2.19545 18.0701L5.96955 18.9121L7.94545 22.1875L11.5 20.6781L15.0545 22.1772L17.0305 18.9018L20.8045 18.0598L20.4491 14.271L23 11.4062ZM9.50318 16.2527L5.53045 12.3406L7.07773 10.821L9.50318 13.2134L15.6191 7.18616L17.1664 8.7058L9.50318 16.2527Z" fill="#F09023" />
                                                </svg>
                                            </span>
                                            <span className="fw-bold fs-xregular mr-2">
                                                {getPlan()}
                                            </span>
                                            <span className="fs-xregular">
                                                {!userStore.companyData ? null : userStore.companyData.name}
                                            </span>
                                        </div>
                                }
                            </div>
                            <p className="subtitle">{props.subtitle}</p>
                        </div>
                        <div className="mt-3">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}