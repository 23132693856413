import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import DashboardLayout from "../dashboard-layout";

export default function DashboardBasic(props) {
    // # SERVER STATES
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ refetchOnMountOrArgChange: true, enabled: true }); // company data (number of ratings, rating, companyname, planname)


    return <>
        <DashboardLayout data={dbGeneralData}>
        </DashboardLayout>
    </>
}